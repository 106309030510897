.agreement-container {
  display: flex;
  flex-direction: column;
  line-height: initial;
  gap: 30px;
  line-height: 2rem;

  section {
    display: flex;
    flex-direction: column;
    p {
      text-align: right;
      margin-bottom: 15px;
    }
    b {
      font-weight: bold;
    }
    li {
      list-style: none;
      font-weight: normal;
    }
    h3 {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .last-section {
    padding-bottom: 50px;
  }
  .list-2depth {
    padding-left: 7px;
  }
  .list-title {
    padding-top: 0.25rem;
    font-weight: bold;
  }
}
