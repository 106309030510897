/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: calc(100% - 52px);
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;
  padding: 0;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  display: flex;
  align-items: center;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.badges .slick-slider {
  /* width: calc(100% - 52px); */
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.badges .slick-track {
  /* 추가설정 */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.badges .slick-slide div {
  display: flex !important;
  gap: 8px;
}

.badges .slick-slide div div {
  font-size: 15px;
  color: #a2a2a2;
  background-color: #f6f6f6;
  padding: 8px 16px;
  padding-top: 10px;
  border-radius: 20px;
  white-space: nowrap;
  width: fit-content;
  margin-right: 8px;
  gap: 0;
}
.badges .slick-slide div div span:after {
  content: "/";
  margin: 0 6px;
}

.badges .slick-slide div div span:last-child::after {
  content: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
