@import "assets/scss/variables";
@import "assets/scss/mixin";

.homecreditloan-container {
  border-bottom: solid 1px #dfdfdf;
  @include mobile {
    border: none;
  }
}

.home-creditloan-wrapper {
  display: flex;
  max-width: $default-max-width;
  padding: 0 $default-desktop-padding;
  margin: 0 auto;
  height: 666px;
  @include tablet {
    padding: 0 $default-mobile-padding;
  }
  @include mobile {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    // gap: 20px;
  }
  .textbox {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    @include mobile {
      text-align: center;
      align-items: center;
    }
    h3 {
      font-size: 32px;
      padding-bottom: 10px;
      @include tablet {
        font-size: 22px;
      }
    }
    h1 {
      font-size: 48px;
      font-weight: 700;
      line-height: 1.4;
      padding-bottom: 16px;
      @include tablet {
        font-size: 32px;
      }
    }
    h5 {
      font-size: 18px;
      color: #959595;
      @include tablet {
        font-size: 18px;
      }
    }
    a {
      text-decoration: none;
      margin-top: 40px;
      width: 250px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $main-color;
      border-radius: 28px;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      border: none;
    }
  }
  .imagebox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (min-width: 759px) {
      margin-left: auto;
    }
    @include mobile {
      padding-bottom: 30px;
    }
    img {
      position: absolute;
      max-width: 650px;
      @include tablet {
        width: 400px;
      }
      @include mobile {
        position: unset;
        justify-content: center;
        max-width: 300px;
        width: 90vw;
      }
    }
  }
}
