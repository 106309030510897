@import "assets/scss/variables";
@import "assets/scss/mixin";

.mobile-navi-menus-container {
  z-index: 301;
  width: 66.67%;
  position: fixed;
  top: -1px;
  background-color: white;
  overflow: hidden;
  right: 0;
  .header {
    display: flex;
    height: 56px;
    align-items: center;
    color: black;
    font-size: 30px;
    padding: 0 8vw;
    justify-content: flex-end;
    svg {
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
  }
  .column-menus {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 40px;
    .navi-item {
      text-align: center;
      margin: 4px 0;
      padding-left: 40px;
      width: 100%;
      height: 40px;
      text-align: left;
      display: flex;
      align-items: center;
      a {
        text-decoration: none;
        color: black;
        font-weight: bold;
        display: block;
        padding: 10px 0;
        font-size: 18px;
        padding: 0;
        &.active {
          color: $main-color;
          @include mobile {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
          }
        }
        .bottom-indicator {
          position: absolute;
          background-color: $main-color;
          height: 40px;
          width: 4px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: -40px;
        }
      }
    }
    .navi-site-menu {
      position: absolute;
      bottom: 0;
      width: 100%;
      .navi-item-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        .navi-item-notice-area {
          width: 100%;
          display: flex;
          flex-direction: column;
          font-size: 12px;
          background-color: $main-light-color;
          overflow: hidden;
          transition: 0.3s;
          padding: 8px 0;
          .navi-item-license {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #666;
            padding: 0 40px;
            height: 36px;
            svg {
              color: #666;
              transform: rotate(180deg);
              transition: 0.3s;
            }
          }
          .navi-item-license-group {
            background-color: white;
            color: #666;
            transition: 0.3s;
            .license-btn {
              display: flex;
              align-items: center;
              height: 36px;
              gap: 8px;
              button {
                cursor: pointer;
                height: 36px;
                position: absolute;
                width: 100%;
                background: none;
                border: none;
                outline: none;
                padding: 0;
              }
              div {
                width: 50px;
                margin-left: 40px;
              }
            }
          }
          a {
            padding: 0 40px;
            background-color: #f1f5f6;
            color: #666;
            text-decoration: none;
            height: 36px;
            display: flex;
            align-items: center;
            font-size: 12px;
            z-index: 1;
          }
        }
      }
      .navi-item-site {
        display: flex;
        align-items: center;
        height: 56px;
        position: relative;
        .navi-item-site-button {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          padding: 10px 24px;
          color: white;
          background-color: #191f28;
          align-items: center;
          border: 1px solid rgba(255, 255, 255, 0);
          border-top: none;
          transition: 0.3s;
          z-index: 5;
          p {
            font-size: 12px;
          }
          .header-arrow {
            width: 0px;
            height: 0px;
            border-bottom: 6px solid white;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            transition: 0.3s;
          }
        }
        .navi-item-site-open {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          padding: 10px 24px;
          color: $text-color;
          background-color: white;
          align-items: center;
          border: 1px solid #ccc;
          border-top: none;
          transition: 0.3s;
          z-index: 1;
          p {
            font-size: 12px;
          }
          .header-arrow {
            width: 0px;
            height: 0px;
            border-bottom: 6px solid $text-color;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            transform: rotate(180deg);
            transition: 0.3s;
          }
        }
        .navi-item-site-accordion {
          position: absolute;
          display: flex;
          flex-direction: column;
          width: 100%;
          background: white;
          bottom: 56px;
          padding: 8px 0;
          border: 1px solid #ccc;
          border-bottom: none;
          transition: 0.3s;
          z-index: 1;
          a {
            color: $text-color;
            text-decoration: none;
            height: 32px;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.mobile-navi-background {
  opacity: 0.35;
  background-color: #000;
  position: fixed;
  z-index: 200;
  width: 100vw;
  height: 100vh;
}

.header-container {
  top: 0;
  position: sticky;
  width: 100%;
  z-index: 100;
  height: 100px;
  padding: 0 40px;
  @include tablet {
    padding: 0 $default-mobile-padding;
  }
  @include mobile {
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 4px 10px);
  }
  .header-wrapper {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
  }
  a {
    height: 46px;
    @include mobile {
      height: 36px;
    }
    img {
      height: inherit;
    }
  }

  .navigation {
    display: flex;
    align-items: center;
    .navi-menu {
      font-size: 30px;
      cursor: pointer;
      svg {
        width: 24px;
      }
    }
    .navi-item {
      position: relative;
    }

    .navi-item-link {
      text-decoration: none;
      color: #000;
      font-weight: bold;
      padding: 0 24px;
      height: 100px;
      position: relative;
      transition: color 0.3s;
      &:hover {
        color: $main-color;
      }
      &.active {
        color: $main-color;
        &::after {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          top: -8px;
          background-color: $main-color;
        }
      }
      .bottom-indicator {
        position: absolute;
        width: 50%;
        min-width: 50px;
        height: 5px;
        background-color: $main-color;
        left: 50%;
        transform: translate(-50%);
      }
    }
    @include tablet {
      gap: 2.5vw;
      .navi-item-link {
        padding: 0;
      }
    }
  }
}

.certify-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: 0 auto;
  padding: 0 40px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: $main-light-color;
  @include mobile {
    padding: 0;
  }
  .modal-body {
    background-color: white;
    border-radius: 20px;
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include desktop {
      max-width: 1200px;
      box-shadow: 0 10px 30px 0 #e3eef1;
    }
    @include tablet {
      max-width: 758px;
    }
    @include mobile {
      height: 100%;
    }
    h2 {
      font-size: 40px;
      margin: 48px 0 16px;
      text-align: center;
      @include mobile {
        line-height: 2.75rem;
        margin: 32px 0 16px;
        font-size: 32px;
      }
      b {
        font-weight: bold;
      }
    }
    p {
      text-align: center;
      font-size: 18px;
      line-height: 1.8rem;
      color: #959595;
      @include mobile {
        font-size: 16px;
      }
    }
    img {
      height: 80px;
      @include mobile {
        height: 64px;
      }
    }
    .modal-input-box {
      padding: 15px 30px;
      margin-top: 24px;
      input {
        border-radius: 0;
        outline: none;
        border: none;
        height: 32px;
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        line-height: 1.75rem;
        border: solid 2px #ccc;
        border-radius: 4px;
        transition: border-bottom 0.3s;
        @include mobile {
          font-size: 20px;
          height: 20px;
        }
        &:focus {
          border: solid 2px $main-color;
        }
        &::placeholder {
          color: #ccc;
          transition: 0.3s;
        }
        &:focus::placeholder {
          opacity: 0;
        }
      }
    }
  }

  .modal-button {
    font-size: 18px;
    border: none;
    cursor: pointer;
    margin: 20px;
    border-radius: 30px;
    padding: 16px 30px;
    width: 250px;
    background-color: $main-color;
    color: white;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    justify-content: center;
    @include mobile {
      width: 148px;
      height: 36px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
