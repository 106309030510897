// Breakpoints
$breakpoint-mobile: 390px;
$breakpoint-tablet: 758px;
$breakpoint-desktop: 1024px;

$main-color: #0071e3;
$main-light-color: #f1f5f6;
$sub-color: #e7f6f9;
$point-color: #00c8bb;
$text-color: #2e2e2e;
$error-color: #ff006f;

:export {
  mainColor: $main-color;
  mainLightColor: $main-light-color;
  subColor: $sub-color;
  pointColor: $point-color;
  textColor: $text-color;
  errorColor: $error-color;
}

$default-max-width: 1280px;
$default-desktop-padding: 40px;
$default-mobile-padding: 8vw;

// @font-face {
//   font-family: "Seoul-Hangang";
//   src: url("../fonts/SeoulHangangB.ttf"), url("../fonts/SeoulHangangEB.ttf"),
//     url("../fonts/SeoulHangangL.ttf"),
//     url("../fonts/SeoulHangangM.ttf") format("woff2");
// }

@font-face {
  font-family: "Seoul-Hangang";
  font-weight: 300;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangLight.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangLight.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangLight.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangLight.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangLight.ttf")
      format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Seoul-Hangang";
  font-weight: 500;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangMedium.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangMedium.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangMedium.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangMedium.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangMedium.ttf")
      format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Seoul-Hangang";
  font-weight: 700;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangBold.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangBold.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangBold.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangBold.ttf")
      format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Seoul-Hangang";
  font-weight: 800;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangExtraBold.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangExtraBold.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangExtraBold.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/seoulhangang/SeoulHangangExtraBold.ttf")
      format("truetype");
  font-display: swap;
}
