@import "assets/scss/variables";
@import "assets/scss/mixin";

.home-notice-container {
  background-color: $main-light-color;
  color: #959595;
  // margin: 0 auto;
  padding: 0 40px;
  line-height: 1.5;
  @include tablet {
    @include mobilePadding;
  }
  .home-notice-wrapper {
    max-width: 1200px;
    padding: 48px 0;
    margin: 0 auto;
    @include mobile {
      padding: 24px 0;
    }
    p {
      font-size: 12px;
    }
    .home-notice-title {
      display: flex;
      align-items: center;
      gap: 4px;
      padding-bottom: 18px;
      @include mobile {
        padding-bottom: 8px;
      }
      svg {
        font-size: 22px;
      }
    }
    .home-notice-content {
      a {
        text-decoration: none;
        color: #959595;
      }
      &::before {
        content: "- ";
      }
    }
    .note-content {
      &::before {
        content: "- ";
      }
    }
  }
}
