@import "assets/scss/variables";
@import "assets/scss/mixin";

.homebanner-container {
  width: 100%;
  height: calc(100vh - 100px);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Seoul-Hangang";
  @include mobile {
    height: auto;
    padding-top: 40px;
  }
}

.homebanner-wrapper {
  margin: 0 auto;
  max-width: $default-max-width;
  height: inherit;
  padding: 0 $default-desktop-padding;
  color: $text-color;
  display: flex;
  @include tablet {
    // flex-direction: column-reverse;
    padding: 0 $default-mobile-padding;
  }
  @include mobile {
    align-items: center;
    justify-content: center;
  }
  .eden-intro {
    margin-left: auto;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    @include mobile {
      margin-left: 0;
      align-items: center;
      text-align: center;
      align-items: center;
    }
    .eden-logo {
      width: 90px;
      padding-bottom: 60px;
      position: relative;
      @include tablet {
        width: 64px;
      }
      @include mobile {
        width: 40px;
      }
    }
    .intro-textbox {
      line-height: 1.4;
      position: relative;
      @include desktop {
        border-right: solid 2px $text-color;
        padding-right: 30px;
      }
      @include mobile {
        text-align: center;
        padding-bottom: 40px;
      }
      h5 {
        padding-bottom: 30px;
        font-size: 22px;
        @include mobile {
          font-size: 18px;
        }
      }
      p {
        font-size: 16px;
        color: #666;
      }
    }
  }
}
