@import "assets/scss/variables";
@import "assets/scss/mixin";

.homephone-container {
  height: 274px;
  background-color: $sub-color;
  @media (max-width: 1200px) {
    height: 312px;
  }
  @include tablet {
    height: 274px;
  }
  @include mobile {
    height: auto;
  }
}

.homephone-wrapper {
  display: flex;
  height: 100%;
  align-items: flex-start;
  max-width: $default-max-width;
  padding: 54px $default-desktop-padding;
  margin: 0 auto;
  @include tablet {
    padding: 36px $default-mobile-padding;
  }
  @include mobile {
    flex-direction: column;
    gap: 40px;
    padding: 0 $default-mobile-padding;
    text-align: center;
    align-items: center;
  }
  .heading-text {
    width: 50%;
    height: 100%;
    align-items: flex-start;
    // gap: 36px;
    display: flex;
    position: relative;
    margin-right: 32px;
    @include tablet {
      // gap: 0;
      margin-right: 0;
    }
    @include mobile {
      flex-direction: column-reverse;
      height: auto;
      width: 100%;
      gap: 16px;
      padding-top: 80px;
      margin-right: 0px;
      align-items: center;
      &::after {
        content: "";
        position: absolute;
        width: 80px;
        height: 3px;
        background-color: #ccc;
      }
    }
    h2 {
      font-weight: bold;
      line-height: 1.4;
      font-size: 32px;
      z-index: 1;
      margin-left: auto;
      padding-right: 80px;
      @media (max-width: 1200px) {
        padding-right: 48px;
      }
      @include tablet {
        padding-right: 40px;
      }
      @include mobile {
        margin: 0;
        padding: 0;
        padding-bottom: 30px;
      }
    }
    img {
      // align-self: flex-end;
      max-width: 240px;
      z-index: 0;
      position: absolute;
      @media (max-width: 1200px) {
        bottom: 0;
      }
      @include tablet {
        width: 192px;
        z-index: 0;
        position: absolute;
      }
      @include mobile {
        width: 170px;
        position: unset;
        align-self: center;
        width: 240px;
        padding-bottom: 40px;
      }
    }
  }
  .homephone-number {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    // gap: 40px;
    @include tablet {
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      align-items: flex-start;
      // width: fit-content;
      margin-left: auto;
    }
    @include mobile {
      width: 100%;
      align-items: center;
      margin: 0px;
      padding-bottom: 88px;
    }
    // 센터 구분선 758px 이상에서만 표시
    @media (min-width: 759px) {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
        width: 1px;
        height: 100%;
        background-color: #b4d9ff;
      }
    }
    .number-box {
      display: flex;
      @media (max-width: 1200px) {
        flex-direction: column;
        margin-right: auto;
        padding-left: 80px;
      }
      @include tablet {
        margin-left: 40px;
        padding-left: 0;
      }
      @include mobile {
        gap: 8px;
        margin: 0;
      }
      h2 {
        font-size: 32px;
        font-weight: bold;
        line-height: 1.4;
        white-space: nowrap;
        padding-right: 40px;
        @media (max-width: 1200px) {
          padding-right: 0;
        }
        @include mobile {
          padding-right: 0px;
        }
      }
      .number-text {
        display: flex;
        flex-direction: column;
        gap: 16px;
        @include mobile {
          align-items: center;
        }
        h1 {
          font-size: 48px;
          font-weight: bold;
          color: $main-color;
          @include tablet {
            font-size: 42px;
          }
          @include mobile {
            font-size: 48px;
          }
          a {
            text-decoration: none;
            color: $main-color;
          }
        }
        p {
          color: #666;
          margin-bottom: 8px;
        }
        .homephone-link {
          height: 56px;
          background-color: white;
          outline: none;
          border-radius: 28px;
          border: 1px solid #b4d9ff;
          font-weight: bold;
          font-size: 18px;
          color: $main-color;
          cursor: pointer;
          text-align: left;
          padding: 0 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;
          @include mobile {
            width: 250px;
          }
          svg {
            font-size: 24px;
          }
        }
      }
    }
  }
}
