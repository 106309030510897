@import "./variables";

// 데스크톱 환경 이상의 스타일 정의 (1025px 이상)
@mixin desktop {
  @media (min-width: 1025px) {
    @content;
  }
}

// 태블릿 이하 환경에서의 스타일 정의 (태블릿 포함함, 1024px 이하)
@mixin tablet {
  @media (max-width: 1024px) {
    @content;
  }
}

// 모바일 이하 환경에서의 스타일 정의 (모바일 포함함, 758px 이하)
@mixin mobile {
  @media (max-width: 758px) {
    @content;
  }
}

// 이 mixin은 거의 사용할 일이 없습니다 (390px 이하)
@mixin lessMobile {
  @media (max-width: 390px) {
    @content;
  }
}

// 패딩 사이즈 정의
@mixin desktopPadding {
  padding-left: $default-desktop-padding;
  padding-right: $default-desktop-padding;
}

@mixin mobilePadding {
  padding-left: $default-mobile-padding;
  padding-right: $default-mobile-padding;
}

@mixin creditLoanContainer {
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 0;
  @include desktop {
    min-height: calc(100vh - 100px);
  }
  @include mobile {
    padding: 0;
  }
}

@mixin creditLoanOuter {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 36px 0;
  gap: 24px;
  @include mobile {
    background-color: $main-color;
    padding: 14px 0;
    color: #fff;
    width: 100vw;
  }
  img {
    width: 80px;
    @include mobile {
      display: none;
    }
  }
  .outer-textbox {
    width: 100vw;
    max-width: 554px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    @include mobile {
      flex-direction: row;
      justify-content: space-between;
    }
    p {
      font-size: 20px;
      @include mobile {
        font-size: 16px;
      }
    }
    .indicator {
      display: flex;
      gap: 6px;
      .pills {
        width: 24px;
        height: 8px;
        border-radius: 4px;
        background-color: #fff;
        @include mobile {
          background-color: $main-color;
          border: solid 1px #fff;
        }
        &.active {
          background-color: $main-color;
          @include mobile {
            background-color: #fff;
          }
        }
      }
    }
  }
}

@mixin creditLoanInner {
  max-width: 554px;
  width: 100vw;
  background-color: #fff;
  border-radius: 20px;
  filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.05));
}

@mixin creditLoanHeading {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
  h2 {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.4;
    color: $text-color;
    @include mobile {
      font-size: 20px;
    }
  }
  p {
    color: #666;
    @include mobile {
      font-size: 12px;
    }
  }
}

@mixin creditLoanConfirmBtn {
  margin: 0 auto;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  border-radius: 28px;
  width: 100%;
  height: 50px;
  background-color: $main-color;
  cursor: pointer;
  @include mobile {
    max-width: 554px;
    width: 100vw;
    transform: translateX(-20px);
    border-radius: 0;
    height: 56px;
    font-size: 18px;
  }
}

@mixin contentModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  .modal-box {
    display: flex;
    flex-direction: column;
    width: 90vw;
    background-color: #fff;
    align-items: center;
    border-radius: 20px;
    filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.05));
    @include mobile {
      width: 100vw;
      max-width: inherit;
      max-height: inherit;
      border-radius: 0;
    }
    .modal-header-logo {
      width: 100vw;
      display: none;
      justify-content: center;
      height: 56px;
      align-items: center;
      background-color: #f1f1f1;
      padding: 12px 0;
      img {
        height: 36px;
      }
      @include mobile {
        display: flex;
      }
    }
    .modal-header {
      width: 100%;
      height: 72px;
      border-bottom: solid 1px #f1f1f1;
      font-size: 18px;
      padding: 24px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      @include mobile {
        height: 56px;
        font-size: 20px;
        padding: 16px 0;
      }
    }
    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: inherit;
      margin: auto 0;
      .LicenseImg {
        max-width: 450px;
        margin: 8px;
        @include mobile {
          max-width: 75%;
        }
      }
    }
    .modal-footer {
      cursor: pointer;
      width: 100%;
      border-top: solid 1px #f1f1f1;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        cursor: pointer;
        border: none;
        background-color: transparent;
        font-weight: bold;
        font-size: 18px;
        color: $text-color;
      }
      @include mobile {
        padding: 12px 0;
        height: 56px;
      }
    }
  }
}

@mixin confirmModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  .confirm-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    background-color: #fff;
    border-radius: 10px;
    @include mobile {
      width: 100%;
      max-width: 500px;
      margin: 0 30px;
    }
    .text-box {
      height: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      h1 {
        font-size: 28px;
        font-weight: bold;
        padding-bottom: 16px;
        @include mobile {
          font-size: 20px;
          line-height: 1.5;
        }
      }
      p {
        font-size: 15px;
        color: #666;
        @include mobile {
          font-size: 12px;
        }
      }
    }
    .button-box {
      display: flex;
      height: 25%;
      width: 100%;
      border-top: solid 1px #ccc;

      div,
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
        cursor: pointer;
        font-size: 18px;
        text-decoration: none;
        &:first-child {
          border-right: solid 1px #ccc;
          font-weight: bold;
          color: #ccc;
        }
        &:last-child {
          color: $main-color;
          font-weight: bold;
        }
      }
      @include mobile {
        height: 48px;
      }
    }
  }
}
