@import "assets/scss/variables";
@import "assets/scss/mixin";

.footer-container-top {
  // margin: 0 auto;
  padding: 0 40px;
  line-height: 1.5;
  background: white;

  // font-size: 12px;
  position: relative;
  @include tablet {
    @include mobilePadding;
  }
  @include mobile {
    display: none;
  }
  .footer-top-box {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    .footer-related-site {
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 12px;
      font-weight: bold;
      border: solid 1px #ccc;
      width: 140px;
      gap: 20px;
      background-color: white;
      cursor: pointer;
      z-index: 1;
      @include tablet {
        width: 112px;
        gap: 8px;
      }
      .arrow {
        width: 0px;
        height: 0px;
        border-bottom: 6px solid #000;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        transition: 0.3s;
      }
    }
    .related-accordion {
      position: absolute;
      right: 0;
      width: 140px;
      text-align: center;
      bottom: 56px;
      padding: 16px;
      background-color: white;
      border: solid 1px #ccc;
      border-bottom: none;
      display: flex;
      flex-direction: column;
      gap: 16px;
      z-index: 0;
      transition: 0.3s;
      @include tablet {
        width: 112px;
      }
      a {
        font-size: 12px;
        cursor: pointer;
        text-decoration: none;
        color: #000;
      }
    }
  }
  .footer-ul {
    display: flex;
    column-gap: 48px;
    list-style: none;
    color: #666;
    padding: 20px 0;
    flex-direction: row;
    @include tablet {
      column-gap: 36px;
    }
    @include mobile {
      display: none;
    }
    .footer-li,
    a {
      font-weight: 500;
      font-size: 12px;
      text-decoration: none;
      color: #666;
      cursor: pointer;
    }
  }
}

.footer-container-bottom {
  background-color: #191f28;
  padding: 36px 40px;
  font-size: 12px;
  position: relative;
  @include tablet {
    @include mobilePadding;
  }
  @include mobile {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .footer-inner {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 24px;
    @include mobile {
      flex-direction: column;
      gap: 16px;
    }
    .footer-company-detail {
      line-height: 1.2;
      color: #666;
      display: flex;
      margin-right: 20px;
      @include mobile {
        margin: 0;
        flex-direction: column;
        gap: 16px;
      }
      strong {
        color: #ccc;
        font-weight: bold;
        padding-right: 28px;
        margin-top: 3px;
      }
      .detail-box {
        display: flex;
        flex-wrap: wrap;
        place-content: flex-start;
        gap: 6px;
      }
      .detail-top {
        padding-bottom: 4px;
        // @include mobile {
        //   padding-bottom: 0;
        // }
        span {
          line-height: 1.25rem;
          // @include mobile {
          //   line-height: 1rem;
          // }
          &:after {
            content: " | ";
          }
          &:last-child::after {
            content: "";
          }
        }
      }
      .detail-bottom {
        // @include mobile {
        //   display: none;
        // }
        span {
          &:after {
            content: " · ";
          }
          &:last-child::after {
            content: "";
          }
        }
      }
    }
    .footer-link {
      display: flex;
      color: #7a7c85;
      gap: 8px;
      align-items: flex-end;
      flex-wrap: wrap;
      @include mobile {
        display: none;
      }
      .link-btn {
        background-color: #333840;
        border-radius: 4px;
        height: 24px;
        position: relative;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        transition: background-color 0.3s;
        font-size: 10px;
        &:hover {
          background-color: white;
          .change-img:nth-child(2) {
            display: none;
          }
          .change-img:last-child {
            display: block;
          }
        }
        button {
          cursor: pointer;
          height: 100%;
          position: absolute;
          width: 100%;
          background: none;
          border: none;
          outline: none;
        }
        .change-img {
          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}

.license-modal-container {
  @include contentModal;
  .modal-box {
    max-width: 500px;
    max-height: 700px;
    @include mobile {
      max-width: inherit;
      max-height: inherit;
    }
  }
}
