@import "assets/scss/variables";
@import "assets/scss/mixin";

.loanable-list-container {
  @include creditLoanContainer;
  min-height: calc(100vh - 100px);
  .loanable-list-outer {
    @include creditLoanOuter;
    padding-top: 10px;
  }
  .loanable-list-inner {
    @include creditLoanInner;
    height: fit-content;
    @include mobile {
      // padding-top: 36px;
      border-radius: 0;
    }

    /* 할부&리스 페이지 스타일 시작 */
    .installment-lease-container {
      display: flex;
      flex-direction: column;
      .select-loanway {
        display: flex;
        border-radius: 20px 20px 0 0;
        overflow: hidden;
        a,
        button {
          width: 50%;
          height: 80px;
          border: none;
          background-color: #fff;
          text-decoration: none;
          cursor: pointer;
          font-size: 18px;
          color: #999;
          box-sizing: content-box;
          border-bottom: solid 2px #ccc;
          display: flex;
          align-items: center;
          justify-content: center;
          &.active {
            border-bottom: solid 2px $main-color;
            color: $main-color;
            font-weight: bold;
            pointer-events: none;
          }
          @include mobile {
            height: 56px;
          }
        }
      }
      .filter-select-container {
        padding: 48px;
        display: flex;
        flex-direction: column;
        gap: 36px;
        .option-title {
          font-weight: bold;
          font-size: 18px;
          display: flex;
          gap: 4px;
          span {
            font-weight: normal;
            font-size: 12px;
            background-color: #ff006f;
            color: #fff;
            padding: 0 8px;
            border-radius: 8px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        // .filter-select-box {
        //   width: 100%;
        //   height: 60px;
        //   border: 1px solid #ccc;
        //   border-radius: 8px;
        //   margin-top: 16px;
        //   padding: 0 24px;
        //   display: flex;
        //   align-items: center;
        //   justify-content: space-between;
        //   p {
        //     font-weight: bold;
        //   }
        // }
      }
      .filter-badge-box {
        display: grid;
        gap: 12px;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 24px;
        .filter-badge {
          flex: 1;
          cursor: pointer;
          @include mobile {
            font-size: 12px;
          }
          input {
            display: none;
          }
          label {
            cursor: pointer;
          }
          .none {
            color: #ccc;
            background: none;
          }
          .active {
            color: $main-color;
            background: $main-light-color;
          }
          .btn {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            border: 1px solid;
            cursor: pointer;
            text-align: center;
          }
        }
      }
      .result-filter {
        height: 52px;
        padding-left: 48px;
        display: flex;
        align-items: center;
        overflow: hidden;
        @include mobile {
          padding: 20px 0px 0px 20px;
        }
        .filter-icon {
          width: 120px;
          height: 36px;
          display: flex;
          gap: 12px;
          padding: 0 16px;
          background-color: $point-color;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 18px;
          color: white;
          font-size: 15px;
          margin-right: 8px;
          text-decoration: none;
          cursor: pointer;
          span {
            white-space: nowrap;
          }
          img {
            width: 16px;
            height: 20px;
          }
        }
        .car-badges {
          display: flex !important;
          gap: 8px;
          // width: calc(100% - 52px) !important;
          margin-left: 16px;
          .badge {
            font-size: 15px;
            color: #a2a2a2;
            background-color: #f6f6f6;
            padding: 8px 16px;
            padding-top: 10px;
            border-radius: 20px;
            white-space: nowrap;
            width: fit-content;
            span {
              position: relative;
              &::after {
                content: "/";
                color: $main-color;
              }
            }
          }
        }
      }

      .car-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 40px 48px;
        background-color: $main-light-color;
        border-radius: 0 0 20px 20px;
        @include mobile {
          padding: 28px 20px;
          border-radius: 0;
          min-height: calc(100vh - 271px);
        }
        .car-box {
          display: flex;
          align-items: center;
          background-color: #fff;
          border-radius: 8px;
          position: relative;
          padding: 24px;
          flex-direction: column;
          min-height: 335px;
          justify-content: space-between;
          box-shadow: 0px 6px 16px #92b2d629;
          .car-head {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 16px;
            .car-name {
              h2 {
                font-size: 18px;
                font-weight: bold;
                padding-bottom: 12px;
              }
              p {
                color: #a2a2a2;
              }
            }
            .car-brand {
            }
          }
          .car-image {
          }
          .car-bottom {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            margin-top: 8px;
            .badge-group {
              display: flex;
              gap: 6px;
              .badge {
                background: $main-light-color;
                padding: 6px 12px;
                border-radius: 16px;
                color: #a2a2a2;
                font-size: 15px;
              }
            }
            .car-price {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 10px;
              font-size: 15px;

              .month {
                color: $main-color;
                b {
                  font-weight: bold;
                  font-size: 32px;
                  margin-left: 8px;
                }
              }
              .released {
                color: #a2a2a2;
                b {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
      .sc-button {
        border: none;
        font-size: 14px;
        @include creditLoanConfirmBtn;
        margin-top: auto;
        &.inactive {
          background-color: #ccc;
        }
      }
    }
    /* 할부&리스 페이지 스타일 종료 */

    .heading {
      padding: 50px;
      width: 100%;
      height: 240px;
      position: relative;
      @include mobile {
        padding: 20px;
        height: 200px;
      }
      h2 {
        font-size: 28px;
        font-weight: bold;
        line-height: 1.4;
        @include mobile {
          font-size: 20px;
        }
      }
      img {
        position: absolute;
        z-index: -1;
        bottom: -32px;
        right: 10px;
        width: 293px;
        @include mobile {
          width: 250px;
        }
      }
    }
    .text-input-box {
      display: flex;
      flex-direction: column;
      gap: 12px;
      position: relative;
      .text-input-title {
        font-size: 16px;
        display: flex;
        gap: 8px;
        align-items: center;
        span {
          font-size: 12px;
          background-color: $error-color;
          color: #fff;
          padding: 0 8px;
          border-radius: 8px;
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mobile {
            font-size: 10px;
            height: 13px;
          }
        }
        @include mobile {
          font-size: 12px;
        }
      }
      .text-input-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 12px;
        span {
          white-space: nowrap;
          padding-bottom: 8px;
          @include mobile {
            color: #ccc;
          }
        }
        section {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }
        input {
          border-radius: 0;
          outline: none;
          border: none;
          width: 100%;
          font-size: 32px;
          font-weight: bold;
          padding: 4px 0;
          border-bottom: solid 2px #ccc;
          transition: border-bottom 0.3s;
          @include mobile {
            font-size: 20px;
          }
          &.error {
            border-bottom: solid 2px $error-color;
            &:focus {
              border-bottom: solid 2px $error-color;
            }
          }
          &:focus {
            border-bottom: solid 2px $main-color;
          }
          &::placeholder {
            color: #ccc;
            transition: 0.3s;
          }
          &:focus::placeholder {
            opacity: 0;
          }
        }
      }
      .text-sub-title {
        position: absolute;
        bottom: -24px;
        font-size: 15px;
        color: $main-color;
      }
    }
    .results-sorter {
      display: flex;
      justify-content: space-between;
      padding: 12px 48px;
      // border-bottom: solid 1px #f1f1f1;
      align-items: center;
      @include mobile {
        padding: 8px 20px;
      }
    }
    .results-sorter-installment {
      display: flex;
      justify-content: space-between;
      padding: 0px 48px;
      // border-bottom: solid 1px #f1f1f1;
      align-items: center;
      @include mobile {
        padding: 8px 20px;
      }
    }
    .sorter-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h5 {
        display: flex;
        align-items: center;
        font-size: 15px;
        padding-bottom: 6px;
        @include mobile {
          font-size: 12px;
          padding-bottom: 3px;
        }
        b {
          padding: 0 8px;
          font-size: 20px;
          font-weight: bold;
          color: $main-color;
          @include mobile {
            font-size: 20px;
          }
        }
      }
      p {
        font-size: 15px;
        color: #ccc;
        @include mobile {
          font-size: 11px;
        }
      }
    }
    .car-card-box {
      display: flex;
      padding: 48px 48px 36px;
      gap: 36px;
      flex-direction: column;
      .car-card {
        padding: 24px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        width: 100%;
        // height: 386px;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        gap: 10px;
        cursor: pointer;
        .card-top {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .card-name {
            display: flex;
            flex-direction: column;
            gap: 8px;
            h3 {
              font-size: 18px;
              font-weight: bold;
            }
            h5 {
              font-size: 15px;
              color: #999;
            }
          }
        }
        .car-img {
          width: 100%;
          height: 130px;
          // background-color: $main-light-color;
        }
        .car-img-skeleton {
          width: 100%;
          height: 181px;
          background-color: $main-light-color;
          border-radius: 8px;
          -webkit-animation: skeleton-light 1.8s infinite ease-in-out;
          animation: skeleton-light 1.8s infinite ease-in-out;
        }
        .card-middle-skeleton {
          width: 100%;
          height: 58px;
          background-color: $main-light-color;
          border-radius: 8px;
          -webkit-animation: skeleton-light 1.8s infinite ease-in-out;
          animation: skeleton-light 1.8s infinite ease-in-out;
        }
        .card-middle {
          padding: 20px 24px;
          background-color: $main-light-color;
          border-radius: 8px;
          display: flex;
          gap: 48px;

          .middle-item {
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              font-size: 12px;
            }
            h3 {
              font-size: 18px;
              font-weight: bold;
              display: flex;
              align-items: center;
              gap: 4px;
              span {
                font-size: 15px;
                font-weight: normal;
              }
              .small {
                font-size: 12px;
              }
            }
            &:first-child {
              color: #666;
              position: relative;
              &:after {
                content: "";
                position: absolute;
                height: 24px;
                width: 1px;
                background: white;
                right: -24px;
              }
            }
            &:last-child {
              color: $main-color;
            }
          }
        }
        .card-last {
          font-size: 12px;
          color: #666;
        }
        .card-last-skeleton {
          width: 40%;
          height: 12px;
          background-color: $main-light-color;
          border-radius: 4px;
          -webkit-animation: skeleton-light 1.8s infinite ease-in-out;
          animation: skeleton-light 1.8s infinite ease-in-out;
        }
      }
    }
    .car-detail-bank {
      padding: 0 48px 48px 48px;
      font-size: 15px;

      .detail-head {
        display: grid;
        justify-content: space-between;
        border-radius: 8px;
        height: 40px;
        grid-template-columns: 3fr 1fr 1fr;
        background-color: $main-light-color;
        padding: 0 24px;
        color: #2f3c69;
        align-items: center;
        span {
          &:last-child {
            position: relative;
            &:before {
              content: "";
              position: absolute;
              height: 12px;
              width: 1px;
              background-color: #ccc;
              left: 0;
            }
          }
        }
      }
      .detail-head-lease {
        display: flex;
        justify-content: space-between;
        border-radius: 8px;
        height: 40px;
        background-color: $main-light-color;
        padding: 0 24px;
        color: #2f3c69;
        align-items: center;
      }
      .center {
        text-align: center;
      }
      .detail-list {
        padding: 0 24px;
        box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
        border-radius: 4px;
        margin-top: 8px;
        .istm-li {
          height: 56px;
          align-items: center;
          justify-content: space-between;
          display: grid;
          grid-template-columns: 3fr 1fr 1fr;
          color: $text-color;
          border-bottom: 1px solid #e2e2e2;
          cursor: pointer;
          position: relative;

          &:last-child {
            border-bottom: none;
          }
        }
        .lease-li {
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $text-color;
          border-bottom: 1px solid #e2e2e2;
          cursor: pointer;
          position: relative;
        }
        .dummy {
          position: absolute;
          background: none;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .bank-name {
          display: flex;
          align-items: center;
          gap: 8px;
          .selected {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            border: 2px solid $main-color;
            display: flex;
            align-items: center;
            justify-content: center;
            div {
              width: 10px;
              height: 10px;
              border-radius: 5px;
              background: $main-color;
            }
          }
          .first {
            font-weight: bold;
            color: #666;
          }
          .last {
            position: relative;
            color: $main-color;
            font-weight: bold;

            &:before {
              content: "";
              position: absolute;
              height: 12px;
              width: 1px;
              background-color: #ccc;
              left: 0;
            }
          }
        }
      }
      .car-detail-btns {
        display: flex;
        align-items: center;
        gap: 16px;
        height: 50px;
        margin-top: 46px;
        button {
          border: none;
          outline: none;
          cursor: pointer;
          color: white;
          height: 100%;
          border-radius: 25px;
          font-size: 16px;
          font-weight: bold;
          &:first-child {
            background-color: $point-color;
            width: 25%;
          }
          &:last-child {
            background-color: $main-color;
            width: 75%;
          }
        }
      }
    }
    .recommended-container {
      background-color: $main-light-color;
      padding: 24px 48px;
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      @include mobile {
        padding: 30px 20px;
        padding-top: 8px;
      }
      h2 {
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 8px;
        padding-top: 24px;
      }
    }
    .results-container {
      width: 100%;
      .results-loader {
        display: flex;
        flex-direction: column;
        height: 40px;
        border-bottom: solid 1px #f1f1f1;
        align-items: center;
        position: sticky;
        background-color: #fff;
        top: 70px;
        z-index: 100;
        @include mobile {
          top: 56px;
        }
        .loader {
          width: 100%;
          height: 8px;
          background-color: #fff;
          filter: drop-shadow(rgba(146, 178, 214, 0.16) 0px 6px 16px);
          .progress-bar {
            width: 50%;
            height: 100%;
            border-radius: 0 4px 4px 0;
            transition: width 0.5s;
            background: linear-gradient(to left, $main-color, $point-color);
            background-size: 150%;
            -webkit-animation: AnimationName 3s ease infinite;
            -moz-animation: AnimationName 3s ease infinite;
            animation: AnimationName 3s ease infinite;
            @keyframes AnimationName {
              0% {
                background-position: 0% 50%;
              }
              50% {
                background-position: 100% 50%;
              }
              100% {
                background-position: 0% 50%;
              }
            }
          }
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 32px;
          color: $main-color;
          font-size: 12px;
        }
      }

      .results-items {
        padding: 24px 48px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        @include mobile {
          padding: 30px 20px;
        }
        h2 {
          font-size: 18px;
          font-weight: bold;
          padding-bottom: 8px;
        }
      }
    }
  }
}

// ProductCard.jsx
.product-card-container {
  background-color: #fff;
  border: solid 1px #f1f1f1;
  border-radius: 8px;
  position: relative;
  filter: drop-shadow(rgba(146, 178, 214, 0.16) 0px 6px 16px);
  .product-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    height: 52px;
    border-bottom: solid 1px #f1f1f1;
    @include mobile {
      height: 40px;
      font-size: 12px;
      padding-left: 16px;
      padding-right: 8px;
    }
    .title {
      display: flex;
      align-items: center;
      .bank-img {
        margin-right: 8px;
        img {
          height: 30px;
        }
      }
      .bank-name {
        font-size: 15px;
      }
    }

    .badges {
      display: flex;
      align-items: center;
      gap: 8px;
      position: relative;
      @include mobile {
        gap: 4px;
      }
      a {
        text-decoration: none;
      }
      .badge {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 68px;
        height: 24px;
        color: rgb(0, 113, 227);
        background-color: rgba(241, 245, 246, 1);
        border-radius: 12px;
        font-size: 12px;
        &.invisible {
          width: 0px;
          height: 0px;
          background-color: rgb(0, 113, 227);
        }
        @include mobile {
          width: 56px;
          height: 24px;
          font-size: 10px;
        }
      }
      .learn-more {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 112px;
        height: 36px;
        background-color: rgb(0, 113, 227);
        color: rgba(255, 255, 255);
        border-radius: 18px;
        font-weight: bold;
        font-size: 15px;
        cursor: pointer;
        transform-origin: right;
        position: absolute;
        right: 0;
      }
    }
  }
  .product-body {
    padding: 16px 24px;
    @include mobile {
      padding: 16px;
    }
    h3 {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 12px;
      @include mobile {
        font-size: 15px;
      }
    }
    .product-infos {
      display: flex;
      justify-content: space-between;
      &.installment-lease-infos {
        justify-content: flex-start;
        gap: 30px;
      }
      .info {
        display: flex;
        flex-direction: column;
        &.installment-lease-info {
          width: 50%;
        }
        h5 {
          font-size: 12px;
          padding-bottom: 4px;
          @include mobile {
            font-size: 10px;
          }
        }
        p {
          font-size: 30px;
          font-weight: bold;
          @include mobile {
            font-size: 22px;
          }
        }
        span {
          padding-top: 8px;
          font-size: 14px;
          align-self: flex-end;
          color: #666;
        }
      }
    }
  }
  .product-point-border {
    position: absolute;
    width: 6px;
    height: 100%;
    background-color: $main-color;
    top: 0;
    left: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}
.loan-loading-box {
  @include creditLoanInner;
  display: flex;
  flex-direction: column;
  height: 542px;
  padding: 48px;
  justify-content: center;
  @include mobile {
    height: calc(100vh - 100px);
    padding: 36px 20px;
    padding-bottom: 0;
    border-radius: 0;
  }
}
.loan-failure-container {
  @include creditLoanInner;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 542px;
  padding: 48px;
  @include mobile {
    height: calc(100vh - 56px);
    padding: 36px 20px;
    padding-bottom: 0;
    border-radius: 0;
  }
  img {
    z-index: 0;
    position: absolute;
    width: 170px;
    right: 0;
    bottom: 84px;
    @include mobile {
      width: 50%;
      bottom: unset;
      top: 300px;
    }
  }
  .failure-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    line-height: 1.4;
    h1 {
      font-size: 28px;
      font-weight: bold;
    }
    p {
      font-size: 15px;
      color: #666;
    }
  }
  .home-btn {
    @include creditLoanConfirmBtn;
    width: 180px;
    height: 48px;
    text-decoration: none;
    font-size: 18px;
  }
}

// FilterModal.jsx
.filter-modal-container {
  @include contentModal;
  height: 100vh;
  .modal-box {
    width: 628px;
    @include mobile {
      width: 100vw;
      height: 100vh;
    }
    .modal-header {
      color: $text-color;
    }
    .modal-body {
      width: 100%;
      padding: 24px 88px;
      flex-direction: column;
      @include mobile {
        padding: 24px;
      }
      .filter-badge-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        span {
          width: 100%;
          padding: 24px 0px 16px;
          font-weight: bold;
          font-size: 15px;
        }
        .span-group {
          display: flex;
          justify-content: space-between;
          span {
            width: fit-content;
            &:last-child {
              color: $main-color;
            }
          }
        }
        .filter-badge-box {
          display: grid;
          gap: 12px;
          grid-template-columns: repeat(3, 1fr);
          .filter-badge {
            flex: 1;
            cursor: pointer;
            @include mobile {
              font-size: 12px;
            }
            input {
              display: none;
            }
            label {
              cursor: pointer;
            }
            .none {
              color: #ccc;
              background: none;
            }
            .active {
              color: $main-color;
              background: $main-light-color;
            }
            .btn {
              height: 48px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              border: 1px solid;
              cursor: pointer;
              text-align: center;
            }
          }
        }
        .filter-range-text {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: #a2a2a2;
          margin-top: 28px;
        }
        .filter-range-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 16px;
          @include mobile {
            width: 95%;
            align-self: center;
            position: relative;
          }
          .slider {
            position: relative;
            width: 414px;
            @include mobile {
              width: 100%;
            }
          }

          .slider__track,
          .slider__range,
          .slider__left-value,
          .slider__right-value {
            position: absolute;
          }

          .slider__track,
          .slider__range {
            border-radius: 3px;
            height: 5px;
          }

          .slider__track {
            background-color: #e4e4e4;
            width: 100%;
            height: 8px;
            z-index: 1;
          }

          .slider__range {
            background-color: $main-color;
            height: 8px;
            z-index: 2;
          }

          .slider__left-value,
          .slider__right-value {
            color: #dee2e6;
            font-size: 12px;
            margin-top: 20px;
          }

          .slider__left-value {
            left: 6px;
          }

          .slider__right-value {
            right: -4px;
          }

          /* Removing the default appearance */
          .thumb,
          .thumb::-webkit-slider-thumb {
            -webkit-appearance: none;
            -webkit-tap-highlight-color: transparent;
          }

          .thumb {
            pointer-events: none;
            position: absolute;
            height: 0;
            width: 420px;
            outline: none;
            @include mobile {
              width: 102%;
            }
          }

          .thumb--zindex-3 {
            z-index: 3;
          }

          .thumb--zindex-4 {
            z-index: 4;
          }

          .thumb--zindex-5 {
            z-index: 5;
          }

          /* For Chrome browsers */
          .thumb::-webkit-slider-thumb {
            background-color: #fff;
            border: none;
            border-radius: 50%;
            box-shadow: 0px 1px 7px #00000029;
            cursor: pointer;
            height: 24px;
            width: 24px;
            margin-top: 4px;
            pointer-events: all;
            position: relative;
          }

          /* For Firefox browsers */
          .thumb::-moz-range-thumb {
            background-color: #fff;
            border: none;
            border-radius: 50%;
            box-shadow: 0px 3px 6px #00000029;
            cursor: pointer;
            height: 24px;
            width: 24px;
            margin-top: 4px;
            pointer-events: all;
            position: relative;
          }
        }
      }
    }
    .modal-footer {
      button {
        font-size: 15px;
        color: #ccc;
        flex: 1;
        height: 100%;
        &:last-child {
          color: $main-color;
        }
      }
    }
  }
}
@keyframes skeleton-light {
  0% {
    background-color: $main-light-color;
  }

  50% {
    background-color: #e2ebed57;
  }

  100% {
    background-color: $main-light-color;
  }
}
