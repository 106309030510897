@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";

// 이곳에서 more-information 폴더 안에 있는 모든 컴포넌트의 스타일을 정의합니다.

.more-information-container {
  @include creditLoanContainer;
  .more-information-outer {
    @include creditLoanOuter;
  }
  .more-information-inner {
    @include creditLoanInner;
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    @include mobile {
      padding: 0 20px;
      padding-top: 36px;
      border-radius: 0;
    }
    .heading {
      @include creditLoanHeading;
      margin-bottom: 20px;
    }
  }
  .confirm-btn {
    @include creditLoanConfirmBtn;
    border: none;
  }

  // 셀렉트형 인풋 스타일
  .select-input-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .select-input-title {
      font-size: 15px;
      display: flex;
      gap: 8px;
      color: #666;
      align-items: center;
      font-weight: normal;
      span {
        font-size: 12px;
        background-color: $error-color;
        color: #fff;
        padding: 0 8px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        @include mobile {
          font-size: 10px;
          height: 13px;
        }
      }
      @include mobile {
        font-size: 12px;
      }
    }
    .select-items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 12px;
      column-gap: 10px;
      .select-item {
        display: flex;
        justify-content: center;
        align-items: center;
        .hidden {
          display: none;
        }
        label {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 140px;
          height: 56px;
          border: solid 1px #ccc;
          border-radius: 8px;
          color: #ccc;
          font-size: 17px;
          text-align: center;
          @include mobile {
            width: 100%;
            font-size: 14px;
          }
        }
      }
    }
    .big-select-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 56px;
      font-size: 17px;
      border-radius: 8px;
      border: solid 1px #ccc;
      color: #ccc;
      cursor: pointer;
      span {
        display: flex;
        align-items: center;
        img {
          width: 30px;
          padding-right: 8px;
        }
      }
    }
  }

  // 텍스트형 인풋 스타일
  .text-input-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    .text-input-title {
      font-size: 15px;
      display: flex;
      color: #666;
      gap: 8px;
      align-items: center;
      font-weight: normal;
      span {
        font-size: 12px;
        background-color: $error-color;
        color: #fff;
        padding: 0 8px;
        border-radius: 8px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mobile {
          font-size: 10px;
          height: 13px;
        }
      }
      @include mobile {
        font-size: 12px;
      }
    }
    .text-input-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 12px;
      span {
        white-space: nowrap;
        // padding-bottom: 8px;
        @include mobile {
          color: #ccc;
        }
      }
      section {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      input {
        border-radius: 0;
        outline: none;
        border: none;
        width: 100%;
        font-size: 32px;
        font-weight: bold;
        padding: 4px 0;
        border-bottom: solid 2px #ccc;
        transition: border-bottom 0.3s;
        @include mobile {
          font-size: 20px;
        }
        &.error {
          border-bottom: solid 2px $error-color;
          &:focus {
            border-bottom: solid 2px $error-color;
          }
        }
        &:focus {
          border-bottom: solid 2px $main-color;
        }
        &::placeholder {
          color: #ccc;
          transition: 0.3s;
        }
        &:focus::placeholder {
          opacity: 0;
        }
      }
    }
    .text-sub-title {
      position: absolute;
      bottom: -24px;
      font-size: 15px;
      color: $main-color;
    }
  }
}
