@import "assets/scss/variables";
@import "assets/scss/mixin";

.intro-container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  .intro-hero {
    width: 100%;
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    img {
      width: 114px;
      padding-bottom: 56px;
    }
    h1 {
      line-height: 1.4;
      text-align: center;
      font-size: 50px;
      @include mobile {
        font-size: 32px;
        padding: 0 36px;
      }
      b {
        font-weight: bold;
      }
    }
    svg {
      padding-top: 100px;
      position: absolute;
      bottom: 30px;
      font-size: 50px;
      cursor: pointer;
      @include mobile {
        font-size: 30px;
      }
    }
  }
  .intro-introduction {
    position: relative;
    .intro-introduction-background {
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 600px;
      z-index: -1;
    }
    .introduction-heading {
      height: 520px;
      max-width: $default-max-width;
      padding: 0 $default-desktop-padding;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.6;
      margin: 0 auto;
      @include mobile {
        align-items: center;
        height: 450px;
        text-align: center;
      }
      p {
        font-size: 18px;
      }
      h2 {
        font-size: 48px;
        font-weight: bold;
        padding-bottom: 16px;
        margin-bottom: 32px;
        position: relative;
        @include mobile {
          padding-bottom: 0;
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 40px;
          height: 4px;
          background-color: $main-color;
          @include mobile {
            position: relative;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            margin-top: 16px;
          }
        }
      }
      h4 {
        font-size: 20px;
        @include mobile {
          font-size: 22px;
        }
      }
    }
  }
  .introduction-cards {
    transform: translateY(-50px);
    max-width: $default-max-width;
    padding: 0 $default-desktop-padding;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include mobile {
      flex-direction: column;
      align-items: center;
      transform: translateY(-30px);
      gap: 30px;
    }
    .card {
      border: none;
      filter: drop-shadow(5px 15px 50px rgba(227, 219, 210, 0.4));
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 350px;
      height: 470px;
      border-radius: 36px;
      gap: 54px;
      @include mobile {
        min-width: 250px;
        width: 100%;
        height: 370px;
        gap: 36px;
      }
      img {
        width: 100px;
      }
      h5 {
        text-align: center;
        font-size: 20px;
        line-height: 2;
        @include tablet {
          font-size: 16px;
          padding: 0 10px;
        }
      }
    }
  }
  .intro-history {
    max-width: $default-max-width;
    width: 100vw;
    padding: 0 $default-desktop-padding;
    margin: 170px auto;
    display: flex;
    flex-direction: column;
    position: relative;
    @include mobile {
      margin: 100px auto;
      padding: 0;
      width: 100%;
    }
    h2 {
      font-size: 48px;
      font-weight: bold;
      padding-bottom: 50px;
      @include mobile {
        text-align: center;
        padding-bottom: 80px;
      }
    }
    .history-contents {
      display: flex;
      width: 100%;
      gap: 60px;
      position: relative;
      flex-direction: column;
      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 4px;
        background-color: $main-color;
      }
      @include mobile {
        padding: 0 40px;
        gap: 28px;
      }
      section {
        display: grid;
        grid-template-columns: 2fr 3fr;
        align-items: start;
        padding-left: 36px;
        @include tablet {
          grid-gap: 30px;
          grid-template-columns: 3fr 4fr;
        }
        @include mobile {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding-left: 20px;
        }
      }
      .year-box {
        display: flex;
        align-items: center;
        gap: 60px;
        @include mobile {
          gap: 16px;
        }
        h3 {
          font-size: 32px;
          font-weight: bold;
          @include mobile {
            font-size: 21px;
            width: 50px;
            text-align: right;
          }
        }
        h4 {
          font-size: 20px;
          line-height: 1.6;
          @include mobile {
            font-size: 15px;
            font-weight: bold;
          }
        }
      }

      .month-boxes {
        display: flex;
        flex-direction: column;
        gap: 10px;
        line-height: 1.4;
        @include mobile {
          gap: 8px;
        }
      }
      .month-box {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        line-height: 1.4;
        h4 {
          color: #ccc;
          font-size: 32px;
          @include mobile {
            width: 50px;
            text-align: right;
          }
        }
        p {
          font-size: 15px;
          line-height: 1.8;
        }
      }
    }
  }
  .intro-emptyspace {
    height: 300px;
    background-color: $main-light-color;
    @include mobile {
      display: none;
    }
  }
}
