@import "assets/scss/variables";
@import "assets/scss/mixin";

.pre-existing-modal-container {
  @include confirmModal;
  .text-box {
    padding: 60px 0;
    height: calc(100% - 64px) !important;
    @include mobile {
      padding: 40px 0;
    }
    h1 {
      line-height: 40px;
      padding-bottom: 0 !important;
    }
    p {
      padding-top: 12px;
      line-height: 1.4;
      font-size: 14px;
      @include mobile {
        padding-top: 4px;
      }
    }
  }
  .button-box {
    @media (min-width: 759px) {
      height: 64px !important;
    }
  }
  .text-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    height: 220px;
    line-height: 1.4;
    color: $text-color;
    @include mobile {
      height: fit-content;
    }
    h1 {
      font-size: 28px;
      font-weight: bold;
      padding-top: 40px;
      padding-bottom: 12px;
      @include mobile {
        font-size: 20px;
        line-height: 1.5;
        padding-top: 32px;
        padding-bottom: 10px;
      }
    }
    p {
      height: 70px;
      font-size: 15px;
      @include mobile {
        height: 76px;
        font-size: 14px;
      }
    }
    .auth-message {
      height: 70px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include mobile {
        justify-content: flex-start;
        height: 76px;
      }
      .auth-input-box {
        position: relative;
        @include mobile {
          margin-bottom: 8px;
        }
        input {
          width: 268px;
          height: 40px;
          border: solid 1px;
          border-color: #c7cad0;
          border-radius: 10px;
          padding: 0 16px;
          font-size: 16px;
          outline: none;
          background: none;
          &:focus {
            border-color: $main-color;
          }
          @include mobile {
            width: 188px;
            height: 32px;
          }
        }
        .auth-time {
          position: absolute;
          color: #ccc;
          font-size: 12px;
          right: 16px;
          height: 100%;
          top: 0;
          display: flex;
          align-items: center;
        }
      }
      .auth-re {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 10px;
          color: $error-color;
        }
        button {
          color: #666;
          border: none;
          outline: none;
          background: none;
          width: fit-content;
          border-bottom: 1px solid #666;
          padding: 0;
          align-self: flex-end;
          cursor: pointer;
          @include mobile {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.terms-modal-container {
  @include contentModal;
  .modal-box {
    height: 90vh;
    max-width: 932px;
    max-height: 600px;
    z-index: 2;
    @include mobile {
      max-height: none;
    }
  }
  .modal-body {
    padding: 40px;
    overflow: auto;
  }
  .modal-footer {
    button {
      height: 70px;
    }
  }
  .modal-background {
    width: 100vw;
    height: 100vh;
    position: absolute;
  }
}

.self-certification-container {
  @include creditLoanContainer;
  .self-certification-outer {
    @include creditLoanOuter;
  }
  .self-certification-inner {
    @include creditLoanInner;
    display: flex;
    flex-direction: column;
    padding: 50px;
    @include mobile {
      padding: 0 20px;
      padding-top: 36px;
      border-radius: 0;
      // min-height: calc(100vh - 100px);
    }
    .heading {
      @include creditLoanHeading;
      margin-bottom: 56px;
    }
    .input-container {
      display: flex;
      flex-direction: column;
      padding-bottom: 48px;
      gap: 12px;
      .input-title {
        font-size: 16px;
        display: flex;
        gap: 8px;
        align-items: center;
        span {
          font-size: 12px;
          background-color: $error-color;
          color: #fff;
          padding: 0 8px;
          border-radius: 8px;
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mobile {
            font-size: 10px;
            height: 12px;
          }
        }
        @include mobile {
          font-size: 12px;
        }
      }
      .user-number {
        display: flex;
        .input-box {
          flex: 1;
        }
      }
      .input-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .input-text {
          background-color: transparent;
          border-radius: 0;
          outline: none;
          border: none;
          width: 100%;
          font-size: 32px;
          font-weight: bold;
          line-height: 1.75rem;
          padding: 4px 0;
          border-bottom: solid 2px #ccc;
          transition: border-bottom 0.3s;
          @include mobile {
            font-size: 20px;
          }
          &.error {
            border-bottom: solid 2px $error-color;
            &:focus {
              border-bottom: solid 2px $error-color;
            }
          }
          &:focus {
            border-bottom: solid 2px $main-color;
          }
          &::placeholder {
            color: #ccc;
            transition: 0.3s;
          }
          &:focus::placeholder {
            opacity: 0;
          }
        }
      }
      .carrier-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 12px;
        column-gap: 10px;
        .carrier-item {
          display: flex;
          justify-content: center;
          align-items: center;
          .carrier {
            display: none;
            &:checked + label {
              background-color: $sub-color;
              color: $main-color;
              font-weight: bold;
              border: solid 1px $main-light-color;
            }
          }
          label {
            cursor: pointer;
            display: flex;
            justify-content: center;
            width: 100%;
            align-items: center;
            height: 56px;
            border: solid 1px #ccc;
            border-radius: 8px;
            color: #ccc;
            font-size: 18px;
            @include mobile {
              font-size: 14px;
              height: 48px;
            }
          }
        }
      }
    }
    .terms-container {
      margin-bottom: 40px;
      .all-agree-box {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 100%;
        font-weight: bold;
        text-align: center;
        height: 56px;
        font-size: 18px;
        @include mobile {
          height: 48px;
        }
      }
      button {
        span {
          transform: translateY(-2px);
        }
      }
      .terms-content {
        margin-top: 16px;
        .terms-line {
          padding: 5px 0px;
          @include mobile {
            margin-bottom: 8px;
          }
        }
        .terms-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .title-span {
            width: 75vw;
            max-width: 360px;
          }
          img,
          span {
            cursor: pointer;
          }
        }
        .terms-parentRef {
          overflow: hidden;
          margin-top: 10px;
          transition: 0.5s;
          display: flex;
          flex-direction: column;
          transition: 0.5s;
          .subTerms {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
          }
          span {
            color: $main-color;
            cursor: pointer;
            padding: 5px 0px;
          }
        }
      }
    }
    .sc-button {
      border: none;
      font-size: 14px;
      @include creditLoanConfirmBtn;
      margin-top: auto;
      &.inactive {
        background-color: #ccc;
      }
    }
    button {
      span {
        transform: translateY(-2px);
      }
    }
  }
}
