@import "assets/scss/variables";
@import "assets/scss/mixin";

.eden-benefits-container {
  max-width: $default-max-width;
  padding: 0 $default-desktop-padding;
  padding-top: 50px;
  margin: 0 auto;
  // margin-top: 150px;
  margin-bottom: 100px;
  @include tablet {
    @include mobilePadding;
  }
  @include mobile {
    padding-top: 150px;
  }
}

.eden-benefits {
  display: flex;
  @include mobile {
    flex-direction: column;
    align-items: center;
  }
  .textbox {
    position: absolute;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    line-height: 1.4;
    @include mobile {
      position: unset;
      align-items: center;
      text-align: center;
    }
    img {
      width: 48px;
      padding-bottom: 22px;
    }
    h2 {
      font-size: 32px;
      font-weight: bold;
      padding-bottom: 48px;
      @include mobile {
        padding-bottom: 20px;
      }
    }
    p {
      line-height: 1.6;
    }
  }
  .imagebox {
    margin-top: 100px;
    @media (min-width: 759px) {
      margin-left: auto;
    }
    @include mobile {
      transform: translateX(0);
      margin-top: 0;
    }
    img {
      width: 950px;
      @include tablet {
        width: 550px;
      }
      @include mobile {
        max-width: 300px;
        width: 90vw;
        padding-top: 30px;
        transform: translateX(8px);
      }
    }
  }
}

.eden-cal-banner {
  margin-top: 100px;
  background-color: $main-color;
  color: #fff;
  height: 180px;
  border-radius: 36px;
  overflow: hidden;
  display: flex;
  position: relative;
  justify-content: flex-end;
  @include tablet {
    margin-top: 50px;
    height: 180px;
  }
  @include mobile {
    justify-content: center;
    height: auto;
  }
  .textbox {
    padding-right: 100px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
    height: inherit;
    @include tablet {
      padding-right: 48px;
    }
    @include mobile {
      padding: 56px 0;
      margin-bottom: 25vw;
      text-align: center;
      align-items: center;
      height: auto;
    }
    h2 {
      z-index: 1;
      font-size: 32px;
      line-height: 1.4;
      font-weight: bold;
      @include tablet {
        font-size: 22px;
      }
      @include mobile {
        font-size: calc(16px + 3vw);
      }
    }
    a {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 22px;
      gap: 6px;
      cursor: pointer;
      text-decoration: none;
      @include tablet {
        font-size: 18px;
      }
    }
  }
  .calloan-image {
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    height: 180px;
    padding-left: 100px;
    @include tablet {
      height: 180px;
      padding-left: 48px;
    }
    @include mobile {
      padding: 0;
      height: auto;
      max-width: 360px;
      width: 70vw;
      right: 0;
      margin: 0 auto;
    }
  }
}

.eden-smart-text {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  h2 {
    text-align: center;
    font-size: 32px;
    line-height: 1.4;
    font-weight: 700;
  }
  a {
    // background-color: $main-color;
    border: solid 1px $main-color;
    text-decoration: none;
    color: $main-color;
    width: 250px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s;
    &:hover {
      background-color: $main-color;
      color: #fff;
    }
  }
}
