@import "assets/scss/variables";
@import "assets/scss/mixin";

.preparing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 56px);
  padding: 0 40px;
  margin: 0 auto;
  text-align: center;
  @include desktop {
    max-width: 1200px;
  }
  @include tablet {
    max-width: 758px;
  }
  .preparing-content-box {
    background-color: white;
    border-radius: 20px;
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 370px;
    }
    @include desktop {
      box-shadow: 0 10px 30px 0 #e3eef1;
    }
    @include mobile {
      height: auto;
      img {
        width: 240px;
      }
    }
    .preparing-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .preparing-content-title {
      font-size: 48px;
      margin: 48px 0 16px 0;
      white-space: nowrap;
      span {
        font-weight: bold;
      }
      @include mobile {
        font-size: 2rem;
        margin: 28px 0 24px 0;
      }
    }
    .preparing-content-sub {
      font-size: 18px;
      line-height: 1.8rem;
      color: #959595;
    }
    .go-to-main-btn {
      margin: 30px;
      border-radius: 30px;
      padding: 15px 30px;
      width: 250px;
      color: $main-color;
      border: solid 1px $main-color;
      font-weight: bold;
      text-decoration: none;
      @include mobile {
        width: 148px;
        height: 36px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
