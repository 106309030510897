@import "assets/scss/_variables.scss";
@import "assets/scss/_mixin.scss";

.heading-button {
  cursor: pointer;
  border: none;
  border-radius: 24px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  margin-left: auto;
  width: 180px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.mobile {
    // width: 100vw;
    width: 100%;
    height: 56px;
    border-radius: 0;
    // transform: translateX(-34px);
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    position: sticky;
  }
}

.loan-product-container {
  // overflow-x: hidden;
  background-color: #f1f1f1;
  .loan-product-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #f1f1f1;
    padding: 0 $default-desktop-padding;
    filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.05));
    @include mobile {
      padding: 0;
      gap: 0;
    }
  }

  // 상단
  .loan-card-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 32px 24px 24px 24px;
    margin: 0 auto;
    margin-top: 64px;
    border-radius: 20px 20px 0 0;
    max-width: 740px;
    width: 100%;
    gap: 24px;
    @include mobile {
      margin-top: 0;
      border-radius: 0;
      padding: 24px 20px;
      gap: 16px;
      margin-bottom: 8px;
    }
    .card-heading {
      display: flex;
      align-items: center;
      padding-bottom: 4px;
      gap: 16px;
      @include mobile {
        padding-left: 10px;
      }
      .bank-logo {
        width: 56px;
        height: 56px;
        border-radius: 28px;
        background-color: #ccc;
      }
      .heading-text {
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: center;
        @include mobile {
          padding-top: 4px;
          gap: 8px;
        }
        .bank-name {
          display: flex;
          font-size: 15px;
        }
        .bank-desc {
          font-size: 20px;
          font-weight: bold;
          @include mobile {
            font-size: 18px;
          }
        }
      }
    }
    .card-body {
      display: flex;
      position: relative;
      padding: 16px 0;
      &::before {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: #f1f1f1;
        top: 0;
      }
      &::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: #f1f1f1;
        bottom: 0;
      }
      .body-text {
        width: 50%;
        display: flex;
        gap: 36px;
        @include mobile {
          flex-direction: column;
          align-items: center;
          gap: 8px;
        }
        .body-circle {
          width: 60px;
          height: 60px;
          background-color: $main-light-color;
          border-radius: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .body-number {
          display: flex;
          flex-direction: column;
          justify-content: center;
          @include mobile {
            align-items: center;
          }
        }
        img {
          width: 34px;
        }
        h3 {
          font-size: 15px;
          padding-bottom: 8px;
          @include mobile {
            font-size: 12px;
          }
        }
        h2 {
          font-size: 28px;
          font-weight: bold;
          color: $main-color;
          @include mobile {
            font-size: 20px;
          }
        }
        &:first-child {
          position: relative;
          padding-left: 36px;
          @include mobile {
            padding-left: 0;
          }
          &::after {
            content: "";
            position: absolute;
            height: 70%;
            width: 1px;
            background-color: #f1f1f1;
            right: 0;
            transform: translate(50%, 25%);
            @include mobile {
              width: 1px;
            }
          }
        }
        &:last-child {
          padding-left: 36px;
          @include mobile {
            padding-left: 30px;
          }
        }
      }
    }
    .interest-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $main-light-color;
      padding: 12px;
      color: $text-color;
      border-radius: 12px;
      section {
        display: flex;
        align-items: center;
        @include mobile {
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
        }
      }
      .icon-text-div {
        display: flex;
        align-items: center;
      }
      .icon-div {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        background-color: white;
        @include mobile {
          width: 64px;
          height: 64px;
        }
        img {
          width: 60px;
          @include mobile {
            width: 48px;
          }
        }
      }
      .interest-text {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-left: 12px;
        p {
          font-size: 12px;
          font-weight: bold;
        }
        h4 {
          font-size: 24px;
          font-weight: bold;
        }
      }
      span {
        font-size: 10px;
        color: #999;
      }
      .calculator-btn {
        display: flex;
        align-items: center;
        gap: 4px;
        padding-right: 4px;
        font-size: 12px;
        color: $text-color;
        position: relative;
        cursor: pointer;
        text-decoration: none;
        .calculator-circle {
          width: 24px;
          height: 24px;
          border-radius: 12px;
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 16px;
          }
        }
        span {
          font-size: 12px;
          color: $text-color;
          @include mobile {
            display: none;
          }
        }
        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 1px;
          background-color: #ccc;
          left: -20px;
          @include mobile {
            width: 1px;
            left: -12px;
          }
        }
      }
    }
  }

  // 하단
  .loan-products-container {
    width: 100%;
    background-color: white;
    max-width: 740px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 64px;
    padding: 40px 48px;
    border-radius: 0px 0px 20px 20px;
    gap: 30px;
    @include desktop {
      // height: 600px;
      // overflow-y: scroll;
    }
    @include mobile {
      border-radius: 0;
      padding: 30px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .container-heading {
      background-color: $main-light-color;
      padding: 18px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      @include mobile {
        padding: 12px 0;
      }
      h1 {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin: 0 auto;
        @include mobile {
          font-size: 16px;
        }
      }
    }

    .descs {
      display: flex;
      flex-direction: column;
      gap: 30px;
      // 서브타이틀
      .desc-heading {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 8px;
        &::before {
          content: "▣ ";
        }
      }

      // 상단 박스형 부가설명
      .additional-desc-box {
        display: flex;
        flex-direction: column;
        border: 1px solid;
        padding: 12px 24px;
        line-height: 1.4;
        border-radius: 10px;
        h3 {
          font-weight: bold;
          padding-bottom: 8px;
          font-size: 14px;
          @include mobile {
            font-size: 12px;
          }
        }
        li {
          font-size: 12px;
          @include mobile {
            line-height: 1.4;
            font-size: 10px;
          }
          &::before {
            content: "- ";
          }
        }
      }

      // 리스트 형태 스타일 정의
      .desc-lists {
        line-height: 1.4;
        li {
          padding-left: 12px;
          font-size: 14px;
          line-height: 2;
          @include mobile {
            font-size: 12px;
            padding-left: 4px;
          }
          &::before {
            content: "- ";
          }
        }
      }

      // 테이블 스타일 정의
      .desc-tables {
        line-height: 2;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        .desc-table {
          border: 1px solid;
          th,
          td {
            padding: 12px;
            line-height: 1.4;
            vertical-align: middle;
            @include mobile {
              padding: 8px;
            }
          }
          .loan-table-thead-th {
            border: 1px solid;
            font-weight: bold;
            text-align: center;
            background-color: $main-light-color;
          }
          .loan-table-tbody-th {
            background-color: $main-light-color;
            border: 1px solid;
            width: 140px;
            @include mobile {
              width: 90px;
            }
          }
          .loan-table-tbody-row {
            border: 1px solid;
            line-height: 2rem;
            .ul-2dep {
              padding-left: 5px;
            }
          }
        }
      }
    }
    // 심의필
    .review {
      line-height: 1.4;
      margin: 48px 0;
      font-size: 12px;
      p {
        text-align: right;
      }
    }
  }
}
